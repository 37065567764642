import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import getFyersInstance from '../utils/fyersInstance';
import getFyersDataSocketInstance from '../utils/fyersDataSocketInstance';
import getFyersOrderSocketInstance from '../utils/fyersOrderSocketInstance';
import { AuthContext } from '../providers/AuthContext';  // Import the AuthContext
import { EMA, ADX } from 'technicalindicators'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { formatNumberIndian } from '../utils/formatNumber';
import QuoteCard from './QuoteCard';
import apiClient from '../utils/apiClient';
// import LivePnL from './LivePnL';

import { useFyersWebSocketContext } from '../providers/FyersWebSocketContext';

function Home() {
    const { subscribeSymbols } = useFyersWebSocketContext();
    
    // const [alertEnabled, setAlertEnabled] = useState(false);
    const checkThresholdInterval = useRef(null);
    const alertInterval = useRef(null);
    // const audio = useRef(new Audio('http://localhost:3001/http://localhost:3001/alert.mp3'));

    const [fyersPosition, setFyersPosition] = useState([]);
    const [fyersPositionIndex, setFyersPositionIndex] = useState(1)
    const [activeSecurities, setActiveSecurities] = useState(null);
    const [runningPnL, setRunningPnL] = useState(0)

    const [capital, setCapital] = useState(40000);

    const [isLoading, setIsLoading] = useState(false);

    const { isReactLogin, fyersAccessToken, profile } = useContext(AuthContext);

    const [selectedIndex, setSelectedIndex] = useState(null)
    const [optionChainData, setOptionChainData] = useState(null)

    const [indexDetails, setIndexDetails] = useState(null)
    const [indexPrevDayData, setIndexPrevDayData] = useState(null)
    const [indexHistory, setIndexHistory] = useState(null)
    const [indexDailyHistory, setIndexDailyHistory] = useState(null)
    const [securitiesData, setSecuritiesData] = useState([])
    const [liveIndexPrice, setLiveIndexPrice] = useState(0)
    
    const [cPivot, setCPivot] = useState(null)
    const [liveADX, setLiveADX] = useState(0)
    const [live9EMA, setLive9EMA] = useState(0)
    const [live9EMADailyCandle, setLive9EMADailyCandle] = useState(0)
    // const [live15EMA, setLive15EMA] = useState(0)

    const [triggeredKillSwitch, setTriggeredKillSwitch] = useState(false)

    const [isIndexExpiry, setIsIndexExpiry] = useState(true)

    //existing instance
    const fyers = getFyersInstance();



    // order window
    const [orderWindowPositionType, setOrderWindowPositionType] = useState('BUY')
    
    // const formatOptionString = (inputString) => {
    //     const [symbol, day, month, strike, type] = inputString.split(" ");
        
    //     // Get the last two digits of the current year
    //     const year = new Date().getFullYear().toString().slice(-2);
        
    //     // Get the first letter of the month
    //     const monthCode = month.charAt(0).toUpperCase();
        
    //     // Determine option type suffix (PE for PUT, CE for CALL)
    //     const optionType = type === "PUT" ? "PE" : "CE";
        
    //     // Construct the formatted string
    //     return `${symbol}${year}${monthCode}${day}${strike}${optionType}`;
    // };

    useEffect(() => {
        // Set an interval to call getFyersPosition every 5 seconds
        const intervalId = setInterval(() => {            
            getFyersPosition();
        }, 11000);

        // Cleanup the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        // console.log('subscribe', subscribeSymbols)
        const handleMessage = (message) => {
            // Handle incoming message
            // console.log('Received message comp 4:', message);
            if (message.symbol) {
                    
                    setSecuritiesData((prevData) =>
                        prevData.map((security) =>
                            security.security === message.symbol
                                ? { ...security, t_c: message.ltp } // Update the t_c for the matching security
                                : security
                        )
                    );
                    if(fyersPosition){
                        setFyersPosition((prevData) =>
                            prevData.map((position) =>{
                                return (position.symbol === message.symbol)
                                ? { 
                                    ...position,
                                    ltp: message.ltp,
                                    unrealized_profit: position.positionType==="LONG"? position.qty * (message.ltp-position.buyAvg) : position.qty * (position.sellAvg-message.ltp)
                                }
                                : position    
                            })
                        );
                    }
                }
        };

        const symbols = ['NSE:MIDCPNIFTY24N0412300PE', 'NSE:TCS-EQ'];

        // Subscribe to symbols when the component mounts
        // console.log('1111')
        if(isReactLogin && fyersAccessToken && indexDetails.length > 0){
            // console.log('2222')
            let securities = indexDetails.find((item) => item.index_name === selectedIndex)?.securities.map(item1 => item1.symbol) || [];
            // // skt.unsubscribe();
            console.log('securities.concat(activeSecurities)', securities.concat(activeSecurities))
            // skt.subscribe(securities.concat(activeSecurities)); 

            subscribeSymbols(securities.concat(activeSecurities), handleMessage);
        }
    }, [indexDetails, subscribeSymbols, activeSecurities, isReactLogin]);
      
    useEffect(()=>{
        const pnl = fyersPosition.reduce((sum, item) => {
            return (sum*1 + item.unrealized_profit + item.realized_profit).toFixed(2);
        }, 0)        
        setRunningPnL(pnl*1)
        // setRunningPnL(30000)
    },[fyersPosition]);

    useEffect(() => {
        document.getElementById('pnl-span').textContent = '₹' + formatNumberIndian(runningPnL);
        const pnlHead = document.getElementById('pnl-head');
        pnlHead.classList.remove('text-bg-dark', 'text-bg-success', 'text-bg-danger');
    
        if (runningPnL === 0) {
            pnlHead.classList.add('text-bg-dark');
        }
        else if (runningPnL > 0) {
            pnlHead.classList.add('text-bg-success');
        } else if (runningPnL < 0 && runningPnL > -4500) {
            pnlHead.classList.add('text-bg-warning');
        } 
        else {
            pnlHead.classList.add('text-bg-danger');
        }


        // Function to exit all positions and activate the kill switch
        const activateKillSwitch = async () => {
            setTriggeredKillSwitch(true);

            const activePositions = fyersPosition.filter(position => position.positionType !== "CLOSED");
            if (activePositions.length > 0) {
                let res = await exitAllOpenPosition(activePositions); // Exits all open positions
                if(res){
                    triggerKillSwitch(); // Additional kill switch logic
                }
            } else {
                // Clear the interval if no active positions are found
                clearInterval(checkThresholdInterval.current);
                checkThresholdInterval.current = null;
                triggerKillSwitch();
                console.log('No active positions, interval cleared immediately.');
            }
        };

        if ((runningPnL <= -4500 || runningPnL >= 11000)) {
            if (!triggeredKillSwitch && !checkThresholdInterval.current) {
                const belowThresholdStartTime = Date.now();
                checkThresholdInterval.current = setInterval(() => {
                    console.log('Running time in secs:', (Date.now() - belowThresholdStartTime) / 1000);
    
                    if (!triggeredKillSwitch && (Date.now() - belowThresholdStartTime >= 5000) && (runningPnL <= -4500 || runningPnL >= 11000)) {
                        activateKillSwitch(); // Call the async function to activate kill switch
                    }
                }, 1000); // Check every second
            }
        }

        // Clear interval if `runningPnL` goes above -4500 and below 11000
        if ((runningPnL > -4500 && runningPnL < 11000) && checkThresholdInterval.current) {
            console.log('Cleared interval');
            clearInterval(checkThresholdInterval.current);
            checkThresholdInterval.current = null;
        }

        return () => {
            console.log('Return cleared interval');
            if (alertInterval.current) clearInterval(alertInterval.current);
            if (checkThresholdInterval.current) clearInterval(checkThresholdInterval.current); // Cleanup `checkThresholdInterval`
        };

    }, [runningPnL]);

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const exitAllOpenPosition = async (activePositions) => {
        const exitOrderPromises = activePositions.map(position => {
            const { exchangeSegment, productType, securityId, qty } = position;
            let transactionType = position.positionType === "LONG" ? "SELL" : "BUY";
            const adjustedQty = qty > 0 ? qty : qty * -1;

            return apiClient.post('/api/dhan/place-order', {
                transactionType,
                exchangeSegment,
                productType,
                securityId,
                qty: adjustedQty
            })
            .then(response => {
                console.log(`Position exited for security ID: ${securityId}, qty is ${qty}`);
                return response.data;
            })
            .catch(err => {
                console.error(`Error on exit order placement for security ID: ${securityId}`, err);
                return null;
            });
        });

        // Wait for all promises to resolve
        try {
            const results = await Promise.all(exitOrderPromises);
            console.log("All kill switch actions completed:", results);
            // return results;
            return true;
        } catch (error) {
            console.error("An error occurred while placing exit orders:", error);
            // throw error;
            return false;
        }
    }

    const triggerKillSwitch = async () => {
        apiClient.post('/api/dhan/activate-kill-switch')
        .then((res)=>{
            console.log('Kill action result: ', res.data)
            if (res.data.kill_switch_activated) {
                alert(res.data.message)
            }
        })
        .catch((err)=>{
            console.log("Kill action error: ", err)
        })
    }
    
    useEffect(()=>{
        document.getElementById('expiry-tag-span').innerHTML = isIndexExpiry ? 
            `<button class="btn btn-danger fw-bold mx-1" style={{width: '250px'}}>Expiry: Yes</button>`
            : 
            `<button class="btn btn-success fw-bold mx-1" style={{width: '250px'}}>Expiry: No</button>`;
    },[isIndexExpiry])

    useEffect(()=>{
        if(indexPrevDayData){
            document.getElementById('pdt-tag-span').innerHTML = (indexPrevDayData.prev_day_o - indexPrevDayData.prev_day_c) > 0 ? 
            `<button class="btn btn-danger fw-bold mx-1" style={{width: '250px'}}>PDT: Bearish</button>`
            : 
            `<button class="btn btn-success fw-bold mx-1" style={{width: '250px'}}>PDT: Bullish</button>`;
        }
    },[indexPrevDayData])

    const quotes = [
        { author: "Jesse Livermore", quote: "The market is never wrong – only opinions are." },
        { author: "Warren Buffett", quote: "Rule No. 1: Never lose money. Rule No. 2: Never forget rule No. 1." },
        { author: "George Soros", quote: "It’s not whether you’re right or wrong that’s important, but how much money you make when you’re right and how much you lose when you’re wrong." },
        { author: "Ed Seykota", quote: "The elements of good trading are: (1) cutting losses, (2) cutting losses, and (3) cutting losses. If you can follow these three rules, you may have a chance." },
        { author: "Alexander Elder", quote: "The goal of a successful trader is to make the best trades. Money is secondary." },
        { author: "Bruce Kovner", quote: "As long as you stick to your discipline, the trading will take care of itself." },
        { author: "Richard Dennis", quote: "You should always have a worst-case exit plan. Always." },
        { author: "Mark Douglas", quote: "You don’t need to know what’s going to happen next to make money." }
    ];

    const [initialLoading, setInitialLoading] = useState(false)
    useEffect(()=>{
        const data = [
            {
                index_name: 'NSE:NIFTY50-INDEX',
                lot_size: 25,
                securities: [
                    {symbol: 'NSE:HDFCBANK-EQ', weightage: 11.02},
                    {symbol: 'NSE:RELIANCE-EQ', weightage: 9.13},
                    {symbol: 'NSE:ICICIBANK-EQ', weightage: 7.73},
                    {symbol: 'NSE:INFY-EQ', weightage: 6.27},
                    {symbol: 'NSE:ITC-EQ', weightage: 4.14},
                    {symbol: 'NSE:TCS-EQ', weightage: 4.12},
                    // {symbol: 'NSE:LT-EQ', weightage: 3.87},
                    // {symbol: 'NSE:BHARTIARTL-EQ', weightage: 3.80},
                    // {symbol: 'NSE:AXISBANK-EQ', weightage: 2.99},
                    // {symbol: 'NSE:SBIN-EQ', weightage: 2.80},
                    {symbol: 'NSE:NIFTY50-INDEX', weightage: 0},
                ]
            },
            {
                index_name: 'NSE:NIFTYBANK-INDEX',
                lot_size: 15,
                securities: [
                    {symbol: 'NSE:HDFCBANK-EQ', weightage: 28.10},
                    {symbol: 'NSE:ICICIBANK-EQ', weightage: 23.81},
                    {symbol: 'NSE:SBIN-EQ', weightage: 9.53},
                    {symbol: 'NSE:KOTAKBANK-EQ', weightage: 9.37},
                    {symbol: 'NSE:AXISBANK-EQ', weightage: 9.32},
                    {symbol: 'NSE:NIFTYBANK-INDEX', weightage: 0},
                ]
            },
            {
                index_name: 'NSE:FINNIFTY-INDEX',
                lot_size: 25,
                securities: [
                    {symbol: 'NSE:HDFCBANK-EQ', weightage: 31.45},
                    {symbol: 'NSE:ICICIBANK-EQ', weightage: 21.47},
                    {symbol: 'NSE:AXISBANK-EQ', weightage: 8.41},
                    {symbol: 'NSE:SBIN-EQ', weightage: 7.46},
                    {symbol: 'NSE:KOTAKBANK-EQ', weightage: 6.70},
                    {symbol: 'NSE:BAJFINANCE-EQ', weightage: 5.31},
                    {symbol: 'NSE:FINNIFTY-INDEX', weightage: 0},
                ]
            },
            {
                index_name: 'NSE:MIDCPNIFTY-INDEX',
                lot_size: 50,
                securities: [
                    {symbol: 'NSE:INDHOTEL-EQ', weightage: 6.25},
                    {symbol: 'NSE:PERSISTENT-EQ', weightage: 6.03},
                    {symbol: 'NSE:LUPIN-EQ', weightage: 5.50},
                    {symbol: 'NSE:CUMMINSIND-EQ', weightage: 5.37},
                    {symbol: 'NSE:FEDERALBNK-EQ', weightage: 5.02},
                    {symbol: 'NSE:COFORGE-EQ', weightage: 4.85},
                    {symbol: 'NSE:HDFCAMC-EQ', weightage: 4.53},
                    {symbol: 'NSE:MIDCPNIFTY-INDEX', weightage: 0},
                ]
            },
            {
                index_name: 'BSE:SENSEX-INDEX',
                lot_size: 10,
                securities: [
                    {symbol: 'NSE:HDFCBANK-EQ', weightage: 13.61},
                    {symbol: 'NSE:RELIANCE-EQ', weightage: 9.88},
                    {symbol: 'NSE:ICICIBANK-EQ', weightage: 9.56},
                    {symbol: 'NSE:INFY-EQ', weightage: 7.28},
                    {symbol: 'NSE:BHARTIARTL-EQ', weightage: 4.89},
                    {symbol: 'NSE:ITC-EQ', weightage: 4.84},
                    {symbol: 'NSE:TCS-EQ', weightage: 4.48},
                    {symbol: 'BSE:SENSEX-INDEX', weightage: 0},
                ]
            },
            {
                index_name: 'BSE:BANKEX-INDEX',
                lot_size: 15,
                securities: [
                    {symbol: 'NSE:HDFCBANK-EQ', weightage: 32.23},
                    {symbol: 'NSE:ICICIBANK-EQ', weightage: 21.68},
                    {symbol: 'NSE:SBIN-EQ', weightage: 17.75},
                    {symbol: 'NSE:AXISBANK-EQ', weightage: 8.99},
                    {symbol: 'NSE:KOTAKBANK-EQ', weightage: 8.69},
                    {symbol: 'BSE:BANKEX-INDEX', weightage: 0},
                ]
            }
        ]
        setIndexDetails(data)
    },[])

    useEffect(() => {
        if(!initialLoading && isReactLogin && securitiesData){
            setInitialLoading(true)

            // set initial index
            const selectedIndexLS = localStorage.getItem('selected_index');
            if (selectedIndexLS !== null) {
                refreshPage(selectedIndexLS)
                console.log('Selected index found:', selectedIndexLS);
            } else {
                refreshPage()
                console.log('Selected index not available in localStorage.');
            }
        }
    }, [isReactLogin, securitiesData]);

    //existing instance
    const skt = getFyersDataSocketInstance();

    //existing instance
    const orderSkt = getFyersOrderSocketInstance();
    
    // useEffect(() => {
    //     if (isReactLogin && indexDetails.length > 0) {
    //         try {
    //             let securities = indexDetails.find((item) => item.index_name === selectedIndex)?.securities.map(item1 => item1.symbol) || [];
    //             // skt.unsubscribe();
    //             console.log('securities.concat(activeSecurities)', securities.concat(activeSecurities))
    //             skt.subscribe(securities.concat(activeSecurities)); 
    //         }   
    //         catch(err){

    //         }
    //     }
    // }, [indexDetails, selectedIndex, fyersPositionIndex, activeSecurities]); //added activeSecurities yesterday

    useEffect(()=>{
        console.log('activeSecurities1', activeSecurities)
    },[activeSecurities])

    /*
    useEffect(() => {
        // console.log(119, isReactLogin)
        if(isReactLogin){
            // market data websocket start
            let securities = indexDetails.find((item)=>{
                return item.index_name === selectedIndex
            }).securities.map(item1 => item1.symbol);

            skt.on('connect', function () {
                console.log('activeSecurities', activeSecurities)
                skt.subscribe(securities.concat(activeSecurities));    
                skt.mode(skt.LiteMode);
            });

            skt.on("message",function(message){
                console.log(message)

                if (message.symbol) {
                    
                    setSecuritiesData((prevData) =>
                        prevData.map((security) =>
                            security.security === message.symbol
                                ? { ...security, t_c: message.ltp } // Update the t_c for the matching security
                                : security
                        )
                    );
                    if(fyersPosition){
                        setFyersPosition((prevData) =>
                            prevData.map((position) =>{
                                return (position.symbol === message.symbol)
                                ? { 
                                    ...position,
                                    ltp: message.ltp,
                                    unrealized_profit: position.side===1?position.qty * (message.ltp-position.buyAvg):position.qty * (position.sellAvg-message.ltp)
                                }
                                : position    
                            })
                        );
                    }
                }
            })

            skt.on('error', function (message) {
                console.log('Error:', message);
            });

            skt.on('close', function () {
                console.log('Socket closed');
            });

            // skt.connect();
            // market data websocket end

            
            // order websocket start
            orderSkt.on("error",function (errmsg) {
                console.log(errmsg)
            })
            
            //for ticks of general data like price-alerts,EDIS
            orderSkt.on('general',function (msg) {
                console.log(msg)
            })
            orderSkt.on('connect',function () {
                orderSkt.subscribe([orderSkt.orderUpdates,orderSkt.tradeUpdates,orderSkt.positionUpdates,orderSkt.edis,orderSkt.pricealerts])
            })
            orderSkt.on('close',function () {
                console.log('closed')
            })
            
            //for ticks of orderupdates
            orderSkt.on('orders',function (msg) {
                // console.log("orders",msg)
            })
            
            //for ticks of tradebook
            orderSkt.on('trades',function (msg) {
                // console.log('trades',msg)
            })
            
            //for ticks of positions
            orderSkt.on('positions',function (msg) {
                getFyersPosition()
                // refreshPage(selectedIndex)
            })
            orderSkt.autoreconnect()
            orderSkt.connect()            
            // order websocket end
            

            return () => {
                skt.close();
                orderSkt.close();
            };
        }
    }, [isReactLogin]);
    */

    const updateActiveSecurities = (newData) => {
        if(activeSecurities){
            setActiveSecurities((prevData) => {
                // Filter new data to include only items with unique symbols
                const filteredData = newData.filter((newItem) => {
                    return !prevData.some((existingItem) => existingItem === newItem.symbol)
                });
                // console.log('filteredData', filteredData.map((item)=>item.symbol))
                // Return updated state with only unique items
                return [...prevData, ...filteredData.map((item)=>item.symbol)];
            });
        }
        else {
            // console.log(123)
            setActiveSecurities(newData.map((item)=>item.symbol))
        }
        
    };

    useEffect(()=>{
        setLiveIndexPrice(securitiesData.find((item)=>item.security===selectedIndex)?.t_c)
        if(indexHistory){
            calculateADX()
            calculateEMAs()
        }
    },[securitiesData])

    const getOptionChainData = async (indexName) => {
        try {
            const response = await fyers.getOptionChain({
                symbol: indexName,
                strikecount: 5,
                timestamp: '',
            });
    

            // console.log(response.data.expiryData[0].date)
            //check if expiry
            const [day, month, year] = response.data.expiryData[0].date.split("-");
            const dateToCheck = new Date(year, month - 1, day);
            const today = new Date();

            const isToday = dateToCheck.getDate() === today.getDate() && dateToCheck.getMonth() === today.getMonth() && dateToCheck.getFullYear() === today.getFullYear();
            setIsIndexExpiry(isToday)

            // const optionChain = response.data.optionsChain; // Access the options chain directly from the response
            setOptionChainData(response.data); // Set the response data in state

            return true;
        } catch (err) {
            console.error('Error fetching data:', err);
            return false; // Return false if an error occurs
        }
    };    

    const getFyersPosition = async () => {
        //for fyers
        // fyers.get_positions().then((response) => {
        //     setFyersPosition(response.netPositions)
        //     updateActiveSecurities(response.netPositions)
        //     setFyersPositionIndex(prevIndex => prevIndex + 1);
        // }).catch((error) => {
        //     console.log(error)
        // })

        //for dhan
        try {
            const response = await apiClient.get('/api/dhan/positions');
            setFyersPosition(response.data.positions);
            updateActiveSecurities(response.data.positions);
            setFyersPositionIndex(prevIndex => prevIndex + 1);
            return response.data.positions; // Return positions to check in the calling function
        } catch (err) {
            console.error('Error fetching Fyers positions:', err);
            return []; // Return an empty array in case of error
        }
    }

    const convertedOptionData = () => {
        const result = [['', 'Put', 'Call']];
      
        // Create a map to store strike prices with CE and PE values
        const strikeMap = {};
    
        // console.log(optionChainData.optionsChain)
        optionChainData.optionsChain.forEach(item => {
          const strikePrice = item.strike_price;
          if(strikePrice!==-1){
            const oi = item.oi; // Open Interest
            const optionType = item.option_type;
        
            if (!strikeMap[strikePrice]) {
              strikeMap[strikePrice] = { PE: null, CE: null };
            }
        
            if (optionType === 'CE') {
              strikeMap[strikePrice].CE = oi;
            } else if (optionType === 'PE') {
              strikeMap[strikePrice].PE = oi;
            }
          }
        });
      
        // Convert the map into the desired format
        for (const [strikePrice, values] of Object.entries(strikeMap)) {
          result.push([strikePrice, values.PE || 0, values.CE || 0]);
        }
      
        return result;
    };
    
    const options = {
        title: 'NIFTY Options',
        hAxis: { title: 'Strike Price' },
        vAxis: { title: 'Open Interest' },
        series: {
            0: { color: '#63D168' }, // Custom color for CE
            1: { color: '#E96767' }  // Custom color for PE
        },
        legend: { position: 'bottom' },
    };

    const refreshPage = async (indexName='NSE:NIFTY50-INDEX') => {
        if(isLoading) return;

        setIsLoading(true)
        setSelectedIndex(indexName);
        localStorage.setItem('selected_index', indexName);
    
        // Utility function to delay
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
        try {
            // Call both async functions and wait for both to resolve
            const pos = await getFyersPosition()
            console.log('pos', pos)
            const [historyResult, optionChainResult] = await Promise.all([
                getIndexHistory(indexName),
                getOptionChainData(indexName)
            ]);
    
            // Check if both were successful
            if (historyResult && optionChainResult) {
                // console.log('Both index history and option chain data fetched successfully!');
    
                // Perform actions here when both responses are received
                const securities = indexDetails.find((item) => {
                    return item.index_name === indexName;
                }).securities;
    
                const today = new Date();
                const today_m_4 = new Date();
                today_m_4.setDate(today.getDate() - 4);
    
                let pd = [];
    
                // Throttle API requests to respect the rate limit (5 requests/second)
                for (let i = 0; i < securities.length; i++) {
                    const security = securities[i];

                    // console.log('aaa', security)
                    // Create the input for API request
                    const inp = {
                        "symbol": security.symbol,
                        "resolution": "D",
                        "date_format": "1",
                        "range_from": today_m_4.toISOString().split('T')[0],
                        "range_to": today.toISOString().split('T')[0],
                        "cont_flag": "1"
                    };
    
                    // console.log(`Fetching data for ${security.symbol}...`);
    
                    // Await the response from fyers.getHistory to ensure sequential execution
                    try {
                        const response = await fyers.getHistory(inp);

                        if(security.symbol===indexName){
                            setIndexPrevDayData({
                                prev_day_o: response.candles[response.candles.length - 2][1],
                                prev_day_h: response.candles[response.candles.length - 2][2],
                                prev_day_l: response.candles[response.candles.length - 2][3],
                                prev_day_c: response.candles[response.candles.length - 2][4]
                            })
                            setCPivot(((response.candles[response.candles.length - 2][2]+response.candles[response.candles.length - 2][3]+response.candles[response.candles.length - 2][4])/3).toFixed(2))
                        }
                        
                        // Push the result to the pd array
                        pd.push({
                            security: security.symbol,
                            weightage: security.weightage,
                            prev_day_o: response.candles[response.candles.length - 2][1],
                            prev_day_h: response.candles[response.candles.length - 2][2],
                            prev_day_l: response.candles[response.candles.length - 2][3],
                            prev_day_c: response.candles[response.candles.length - 2][4],
                            t_o: response.candles[response.candles.length - 1][1],
                            t_h: response.candles[response.candles.length - 1][2],
                            t_l: response.candles[response.candles.length - 1][3],
                            t_c: response.candles[response.candles.length - 1][4],
                        });
                    } catch (err) {
                        console.log(`Error fetching data for ${security.symbol}:`, err);
                    }
    
                    // Delay next request to ensure we don't exceed the rate limit
                    if ((i + 1) % 10 === 0) { // After every 10 requests, delay for 1 second
                        await delay(1100);
                    }
                }
    
                // Set the accumulated securities data after all requests are done
                setSecuritiesData(pd);
                setIsLoading(false)
    
            } else {
                console.log('Failed to fetch one or both datasets.');
                // Handle failure case
                setIsLoading(false)
            }
        } catch (err) {
            console.error('An error occurred while fetching data:', err);
            // Handle unexpected errors
            setIsLoading(false)
        }
    };
    

    const getIndexHistory = async (index_name) => {
        try {
            const today = new Date();

            //for 15 minutes timeframe
            const today_m_7 = new Date();
            today_m_7.setDate(today.getDate() - 7);
            const inp = {
                "symbol": index_name,
                "resolution": "15", //15minutes
                "date_format": "1",
                "range_from": today_m_7.toISOString().split('T')[0],
                "range_to": today.toISOString().split('T')[0],
                "cont_flag": "1"
            };
            const response = await fyers.getHistory(inp);
            setIndexHistory(response.candles);

            //for daily timeframe
            const today_m_60 = new Date();
            today_m_60.setDate(today.getDate() - 360);
            const inp1d = {
                "symbol": index_name,
                "resolution": "1D", //1 day
                "date_format": "1",
                "range_from": today_m_60.toISOString().split('T')[0],
                "range_to": today.toISOString().split('T')[0],
                "cont_flag": "1"
            };
            const response1 = await fyers.getHistory(inp1d);
            // console.log('response1', response1)
            setIndexDailyHistory(response1.candles);
    
            return true;
        } catch (err) {
            console.error('Error fetching index history:', err);
            return false;
        }
    };    

    const sortedSecuritiesData = [...securitiesData].sort((a, b) => b.weightage - a.weightage);

    const positiveWeightagePer = securitiesData.reduce((sum, item) => {
        return item.prev_day_c < item.t_c ? sum + item.weightage : sum;
    }, 0);

    const negativeWeightagePer = securitiesData.reduce((sum, item) => {
        return item.prev_day_c > item.t_c ? sum + item.weightage : sum;
    }, 0);

    const countAbovePrevDayHigh = securitiesData.filter((item) => {
        return item.prev_day_h < item.t_c;
    }).length;

    const countBelowPrevDayLow = securitiesData.filter((item) => {
        return item.prev_day_l > item.t_c;
    }).length;

    function calculateEMAs(){
        if(indexHistory){
            const lp = securitiesData.find((item)=>item.security===selectedIndex);

            let prices = indexHistory.map(candle => candle[candle.length - 2]);

            // console.log('prices', prices)

            if(lp){
                prices[prices.length-1] = lp.t_c
            }

            const ema9s = EMA.calculate({period : 9, values : prices.slice(-100)})     
            setLive9EMA(ema9s[ema9s.length-1].toFixed(2))

            // const ema15s = EMA.calculate({period : 15, values : prices.slice(150)})     
            // setLive15EMA(ema15s[ema15s.length-1].toFixed(2))

            //calculate 9ema for daily candles
            prices = indexDailyHistory.map(candle => candle[candle.length - 2]);
            if(lp){
                prices[prices.length-1] = lp.t_c
            }
            const ema9sDaily = EMA.calculate({period : 9, values : prices.slice(-100)})     
            // console.log('ema9sDaily', ema9sDaily)
            setLive9EMADailyCandle(ema9sDaily[ema9sDaily.length-1].toFixed(2))
        }
    }

    function calculateADX() {
        if(indexHistory && securitiesData){
            const lp = securitiesData.find((item)=>item.security===selectedIndex);

            let input = {
                period: 14,
                high: indexHistory.map(item => item[2]),
                low: indexHistory.map(item => item[3]),
                close: indexHistory.map(item => item[4])
            };
            if(lp){
                const lastIndex = input.high.length;
                input.high[lastIndex] = lp.t_h
                input.low[lastIndex] = lp.t_l
                input.close[lastIndex] = lp.t_c

                var adx = new ADX(input);
                var results = [];
                input.close.forEach(function(close,index) {
                    var result = adx.nextValue({
                        close: input.close[index],
                        high: input.high[index],
                        low: input.low[index]
                    });
                    if(result){
                        results.push((result.adx).toFixed(2))
                    }
                });
                
                setLiveADX(results[results.length-2])
            }
        }        
    }

    const tdLoading = (count) => {
        return (
            <>
              {Array.from({ length: count }, (_, i) => (
                <td key={i}><Skeleton count={0.75} /></td>
              ))}
            </>
          );
    }

    return (
        <div>
            { optionChainData ?
                <>
                    {/* <LivePnL/> */}

                    {/*<div>
                        <audio ref={audio} src="http://localhost:3001/alert.mp3" preload="auto" />
                    </div>*/}

                    <ul className="nav col-12 col-md-auto mb-5 justify-content-center mb-md-0">
                        <li className={selectedIndex=='NSE:NIFTY50-INDEX'?'active':null}><button href="#" className="btn btn-sm btn-outline-success mx-1 alert alert-success mb-0 py-1 px-2 fw-bold" onClick={()=>refreshPage('NSE:NIFTY50-INDEX')}>Nifty 50</button></li>
                        <li className={selectedIndex=='NSE:NIFTYBANK-INDEX'?'active':null}><button href="#" className="btn btn-sm btn-outline-success mx-1 alert alert-success mb-0 py-1 px-2 fw-bold" onClick={()=>refreshPage('NSE:NIFTYBANK-INDEX')}>Bank Nifty</button></li>
                        <li className={selectedIndex=='NSE:FINNIFTY-INDEX'?'active':null}><button href="#" className="btn btn-sm btn-outline-success mx-1 alert alert-success mb-0 py-1 px-2 fw-bold" onClick={()=>refreshPage('NSE:FINNIFTY-INDEX')}>Fin Nifty</button></li>
                        <li className={selectedIndex=='NSE:MIDCPNIFTY-INDEX'?'active':null}><button href="#" className="btn btn-sm btn-outline-success mx-1 alert alert-success mb-0 py-1 px-2 fw-bold" onClick={()=>refreshPage('NSE:MIDCPNIFTY-INDEX')}>Midcap Nifty</button></li>
                        <li className={selectedIndex=='BSE:SENSEX-INDEX'?'active':null}><button href="#" className="btn btn-sm btn-outline-success mx-1 alert alert-success mb-0 py-1 px-2 fw-bold" onClick={()=>refreshPage('BSE:SENSEX-INDEX')}>Sensex</button></li>
                        <li className={selectedIndex=='BSE:BANKEX-INDEX'?'active':null}><button href="#" className="btn btn-sm btn-outline-success mx-1 alert alert-success mb-0 py-1 px-2 fw-bold" onClick={()=>refreshPage('BSE:BANKEX-INDEX')}>Bankex</button></li>
                    </ul>

                    <div className="row my-3">
                        <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4>{ (!isLoading && optionChainData.indiavixData.ltp) || <Skeleton count="0.4"/>}</h4>
                                <h6 className='fw-bold'>INDIAVIX</h6>
                            </div>
                        </div>
                        <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4>{ (!isLoading && formatNumberIndian(liveIndexPrice)) || <Skeleton count="0.4"/>}</h4>
                                <h6 className='fw-bold'>INDEX</h6>
                            </div>
                        </div>
                        <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4>{ (!isLoading && (optionChainData.putOi/optionChainData.callOi).toFixed(2)) || <Skeleton count="0.4"/>}</h4>
                                <h6 className='fw-bold'>PCR</h6>
                            </div>
                        </div>
                        {/* <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4>{ !isLoading && live15EMA || <Skeleton count="0.4"/>}</h4>
                                <h6>15 EMA</h6>
                            </div>
                        </div> */}
                        <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4 className={live9EMA>liveIndexPrice?'text-danger':'text-success'}>{ (!isLoading && formatNumberIndian(live9EMA)) || <Skeleton count="0.4"/>}</h4>
                                <h6 className='fw-bold'>9 EMA</h6>
                            </div>
                        </div>
                        <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4 className={cPivot>liveIndexPrice?'text-danger':'text-success'}>{ (!isLoading && formatNumberIndian(cPivot)) || <Skeleton count="0.4"/>}</h4>
                                <h6 className='fw-bold'>C. PIVOT</h6>
                            </div>
                        </div>
                        <div className="col-2 text-center">
                            <div className='border rounded bg-light py-3'>
                                <h4 className={liveADX<25?'text-warning':null}>{ (!isLoading && liveADX) || <Skeleton count="0.4"/>}</h4>
                                <h6 className='fw-bold'>ADX</h6>
                            </div>
                        </div>
                    </div>

                    {
                        isLoading ?
                            <Skeleton className='mb-3' height={70}/>
                            :
                            <div className="row">
                                {
                                    live9EMA > liveIndexPrice && cPivot > liveIndexPrice &&
                                    <div className="col-12">
                                        <div className='alert alert-danger text-center fw-bold'>
                                            <h4 className='mb-0'>LOOK FOR SELLING ONLY</h4>
                                            <p className='mb-0'>Befor entry check setup & entry near 9 EMA only.</p>
                                            </div>
                                    </div>
                                }
                                {live9EMA < liveIndexPrice && cPivot < liveIndexPrice &&
                                    <div className="col-12">
                                        <div className='alert alert-success text-center fw-bold'>
                                            <h4 className='mb-0'>LOOK FOR BUYING ONLY</h4>
                                            <p className='mb-0'>Befor entry check setup & entry near 9 EMA only.</p>
                                        </div>
                                        
                                    </div>
                                }
                                {
                                    (live9EMA > liveIndexPrice && cPivot < liveIndexPrice) || (live9EMA < liveIndexPrice && cPivot > liveIndexPrice) &&
                                    <div className="col-12">
                                        <div className='alert alert-warning'><h4 className='mb-0 text-center'>SIDEWAYS INDEX</h4></div>
                                    </div>
                                }
                            </div>
                    }

                    <div className='row mb-3'>
                        <div className="col-md-6">
                            <div className="">
                                {
                                    isLoading ?
                                    <Skeleton height={330}/>
                                    :
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="330px"
                                        data={convertedOptionData()}
                                        options={options}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <table className="table table-light table-sm small border border-dark">
                                <thead className='table-dark text-center'>
                                    <tr className='align-middle'>
                                        <th>+ Weight</th>
                                        <th>- Weight</th>
                                        <th>Above High</th>
                                        <th>Below Low</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        isLoading ?
                                        <tr>
                                            <td><Skeleton/></td>
                                            <td><Skeleton/></td>
                                            <td><Skeleton/></td>
                                            <td><Skeleton/></td>
                                        </tr>
                                        :
                                        <tr className='fw-bold'>
                                            <td className='text-success'>{positiveWeightagePer.toFixed(2)}%</td>
                                            <td className='text-danger'>{negativeWeightagePer.toFixed(2)}%</td>
                                            <td>{countAbovePrevDayHigh}/{securitiesData.length}</td>
                                            <td>{countBelowPrevDayLow}/{securitiesData.length}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <table className="table table-light table-sm small border border-dark">
                                <thead className='table-dark'>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="25%">Stock (Weight)</th>
                                        <th className='text-end' width="10%">Open</th>
                                        <th className='text-end' width="10%">High</th>
                                        <th className='text-end' width="10%">Low</th>
                                        <th className='text-end' width="10%">Close</th>
                                        <th className='text-end' width="10%">APDH</th>
                                        <th className='text-end' width="10%">BPDL</th>
                                        <th className='text-end' width="10%">+/-</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading ?
                                        [1,2,3,4,5,6,7].map((number, index) => (
                                            <tr key={'s'+index}>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                                <td><Skeleton/></td>
                                            </tr>
                                        ))
                                        :
                                        securitiesData && sortedSecuritiesData.map((item,i)=>(
                                            <tr key={'s'+i}>
                                                <td>{i+1}</td>
                                                <td>{item.security.split(":")[1].split("-")[0]}<span className='text-secondary'>({item.weightage}%)</span></td>
                                                <td className='text-end'>{item.t_o}</td>
                                                <td className='text-end'>{item.t_h}</td>
                                                <td className='text-end'>{item.t_l}</td>
                                                <td className='text-end'>{item.t_c}</td>
                                                <td className='text-end'>{item.t_c>item.prev_day_h?<i className="fa-solid fa-circle-check text-success"></i>:'N'}</td>
                                                <td className='text-end'>{item.t_c<item.prev_day_l?<i className="fa-solid fa-circle-check text-success"></i>:'N'}</td>
                                                <td className={`text-end ${item.t_c>item.prev_day_c?'text-success':'text-danger'}`}>{item.t_c>item.prev_day_c ? ((item.t_c-item.prev_day_c)/item.prev_day_c*100).toFixed(2) : ((item.prev_day_c-item.t_c)/item.prev_day_c*100).toFixed(2) }%</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-12 col-lg-8 col-xl-6">
                            <h5>Strategy:</h5>
                            <table className='table table-sm table-border'>
                                <thead className='table-dark text-nowrap align-middle text-center'>
                                    <tr>
                                        <th rowSpan={2}>Side</th>
                                        <th rowSpan={2}>PD Trend</th>
                                        <th colSpan={2}>15 EMA</th>
                                        <th rowSpan={2}>C. Pivot</th>
                                        <th rowSpan={2}>Note</th>
                                    </tr>
                                    <tr>
                                        <th>Daily Chart</th>
                                        <th>15 M. Chart</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    <tr>
                                        <td>BUY</td>
                                        <td>Positive</td>
                                        <td>Positive</td>
                                        <td>Positive</td>
                                        <td>Positive</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>BUY</td>
                                        <td>Positive</td>
                                        <td>Negative</td>
                                        <td>Negative</td>
                                        <td>Negative</td>
                                        <td className='text-start'>If too much selling without retracement and far away from 15 EMA in 15 minutes chart.</td>
                                    </tr>
                                    <tr>
                                        <td>SELL</td>
                                        <td>Negative</td>
                                        <td>Negative</td>
                                        <td>Negative</td>
                                        <td>Negative</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>SELL</td>
                                        <td>Negative</td>
                                        <td>Positive</td>
                                        <td>Positive</td>
                                        <td>Positive</td>
                                        <td className='text-start'>If too much buying without retracement and far away from 15 EMA in 15 minutes chart.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-6">
                            <h5>Price Indication:</h5>
                            <table className='table table-sm'>
                                <thead>
                                    <tr className='table-dark'>
                                        <th width="50%">Indicator</th>
                                        <th width="25%">Value</th>
                                        <th width="25%">Side</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {indexPrevDayData &&
                                    <tr>
                                        <td className='fw-bold'>Prev. Day Trend</td>
                                        <td>{(indexPrevDayData.prev_day_o-indexPrevDayData.prev_day_c>0) ? 'Negative' : 'Positive'}</td>
                                        <td className={indexPrevDayData.prev_day_o-indexPrevDayData.prev_day_c>0?'text-danger':'text-success'}>{(indexPrevDayData.prev_day_o-indexPrevDayData.prev_day_c>0) ? 'Selling' : 'Buying'}</td>
                                    </tr>}
                                    <tr>
                                        <td className='fw-bold'>9 EMA (daily)</td>
                                        {
                                            isLoading ?
                                                tdLoading(2)
                                            :
                                            <>
                                                <td>{live9EMADailyCandle}<span className={liveIndexPrice-live9EMADailyCandle>0?'text-success':'text-danger'}>({Math.round(liveIndexPrice-live9EMADailyCandle)})</span></td>
                                                <td>{ live9EMADailyCandle>liveIndexPrice ? <span className='text-danger'>Selling</span>:<span className='text-success'>Buying</span>}</td>
                                            </>
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>9 EMA (15 minutes)</td>
                                        {
                                            isLoading ?
                                                tdLoading(2)
                                            :
                                            <>
                                                <td>{live9EMA} <span className={liveIndexPrice-live9EMA>0?'text-success':'text-danger'}>({Math.round(liveIndexPrice-live9EMA)})</span></td>
                                                <td>{ live9EMA>liveIndexPrice ? <span className='text-danger'>Selling</span>:<span className='text-success'>Buying</span>}</td>
                                            </>
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Central Pivot</td>
                                        {
                                            isLoading ?
                                                tdLoading(2)
                                            :
                                            <>
                                                <td>{cPivot} <span className={liveIndexPrice-cPivot>0?'text-success':'text-danger'}>({Math.round(liveIndexPrice-cPivot)})</span></td>
                                                <td>{ cPivot>liveIndexPrice ? <span className='text-danger'>Selling</span>:<span className='text-success'>Buying</span>}</td>
                                            </>
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>ADX</td>
                                        {
                                            isLoading ?
                                                tdLoading(2)
                                            :
                                            <>
                                                <td>{liveADX}</td>
                                                <td>{ liveADX<25 ? <span className='text-warning'>Sideways</span>:<span className='text-success'>Volatile</span>}</td>
                                            </>
                                        }
                                    </tr>
                                    {/* <tr>
                                        <td className='fw-bold'>PCR</td>
                                        {
                                            isLoading ?
                                                tdLoading(2)
                                            :
                                            <>
                                                <td>{(optionChainData.putOi/optionChainData.callOi).toFixed(2)}</td>
                                                <td>
                                                    { (optionChainData.putOi/optionChainData.callOi) <= 0.8 ?
                                                        <span className='text-success'>Bullis</span>
                                                        :
                                                        (
                                                            (optionChainData.putOi/optionChainData.callOi) >= 1.2 ?
                                                                <span className='text-danger'>Bearish</span>
                                                                :
                                                                <span className='text-success'>Sideways</span>
                                                        )
                                                    }
                                                </td>
                                            </>
                                        }
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {fyersPosition && fyersPosition.length > 0 &&
                        <div className="accordion mb-3" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button py-2 alert alert-secondary mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Today's Positions
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body p-0">
                                        <table className="table tabls-sm small">
                                            <thead className='table-dark'>
                                                <tr>
                                                    <th width="5%">#</th>
                                                    <th width="15%">Symbol</th>
                                                    <th className='text-end' width="8.8%">Qty.</th>
                                                    <th className='text-end' width="8.8%">Runn. Qty.</th>
                                                    <th className='text-end' width="8.8%">Buy Avg.</th>
                                                    <th className='text-end' width="8.8%">Sell Avg.</th>
                                                    <th className='text-end' width="8.8%">LTP</th>
                                                    <th className='text-end' width="12%">RP</th>
                                                    <th className='text-end' width="12%">UP</th>
                                                    <th className='text-end' width="12%">Net Profit(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    fyersPosition && fyersPosition.map((pos,i)=>(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{pos.symbol}</td>
                                                            <td className='text-end'>{formatNumberIndian(pos.buyQty-pos.qty)}</td>
                                                            <td className='text-end'>{formatNumberIndian(pos.qty)}</td>
                                                            <td className='text-end'>{(pos.buyAvg).toFixed(2)}</td>
                                                            <td className='text-end'>{(pos.sellAvg).toFixed(2)}</td>
                                                            <td className='text-end'>{formatNumberIndian(pos.ltp)}</td>
                                                            <td className={`text-end ${pos.realized_profit>=0?'text-success':'text-danger'}`}>{formatNumberIndian(pos.realized_profit)}</td>
                                                            <td className={`text-end ${pos.unrealized_profit>=0?'text-success':'text-danger'}`}>{formatNumberIndian(pos.unrealized_profit)}</td>
                                                            <td className={`text-end ${pos.realized_profit+pos.unrealized_profit>=0?'text-success':'text-danger'}`}>{formatNumberIndian(pos.realized_profit+pos.unrealized_profit)}</td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr className='table-light'>
                                                    <td colSpan={9} className='fw-bold'>Overall</td>
                                                    <td className={`text-end ${runningPnL>=0?'text-success':'text-danger'}`}>₹{formatNumberIndian(runningPnL)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-4">
                            <div className="border rounded px-3 pt-3">
                                <h5>Expiries of different indices:</h5>
                                <ol>
                                    <li>Monday: Nifty Midcap/Bankex</li>
                                    <li>Tuesday: Financial Nifty</li>
                                    <li>Wednesday: Bank Nifty</li>
                                    <li>Thursday: Nifty 50</li>
                                    <li>Friday: Sensex</li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-md-8">
                            {/* <h5>Rules to be follow:</h5> */}
                            <table className='table table-sm'>
                                <thead>
                                    <tr className='table-dark'>
                                        <th>Rules</th>
                                        <th>Limit</th>
                                        <th>Expiry Limit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='fw-bold'>Max trade</td>
                                        <td>4</td>
                                        <td>2(Expiry contract)</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Max loss</td>
                                        <td>Rs. {formatNumberIndian(capital/10)} (10%)</td>
                                        <td>Rs. {formatNumberIndian(capital/10)} (10%)</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Max capital utilization</td>
                                        <td>Rs. {formatNumberIndian(capital)} (100%)</td>
                                        <td>Rs.{formatNumberIndian(capital/2)} (50%)</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Strike Price</td>
                                        <td>ATM only</td>
                                        <td>ITM only</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Timing</td>
                                        <td>9:45-11:00 & 1:30-3:00</td>
                                        <td>9:30-10:30 & 2:00-3:00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <QuoteCard quotes={quotes} />
                        </div>
                    </div>

                    <button className="btn btn-primary order-window-btn" data-bs-toggle="modal" data-bs-target="#orderModal">
                        <i className="fa-solid fa-cart-shopping"></i>
                    </button>

                    <div className="modal fade" id="orderModal" tabIndex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
                        <div className={`modal-dialog modal-lg alert ${orderWindowPositionType==='BUY'?'alert-success':'alert-danger'} p-0`}>
                            <div className="modal-content">
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title mb-0">{selectedIndex}</h5>
                                        <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                    <div className="card-body">
                                        {/* <div className="row">
                                            <div className="col-6">
                                                <button type="button" className="btn btn-sm btn-success fw-bold px-3">Buy</button>
                                                <button type="button" className="btn btn-sm btn-danger fw-bold px-3 ms-1">Sell</button>
                                            </div>
                                        </div> */}
                                        {/* <div className="row mt-3">
                                            <div className="col-4">
                                                <button className='btn btn-sm btn-outline-success'>Market Order</button>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-3">

                                                <fieldset className="border rounded-3 px-3">
                                                    <legend className="float-none w-auto small mb-0">Quantity(x{indexDetails.find((i)=>{return i.index_name===selectedIndex}).lot_size})</legend>
                                                    <input type="number" className="form-control" placeholder="" aria-label="Quantity" />
                                                </fieldset>
                                            </div>
                                            <div className="col-3">
                                                <fieldset className="border rounded-3 px-3">
                                                    <legend className="float-none w-auto small mb-0">Price</legend>
                                                    <input type="number" className="form-control" placeholder="" aria-label="Quantity" />
                                                </fieldset>
                                            </div>
                                            <div className="col-3">
                                                <fieldset className="border rounded-3 px-3">
                                                    <legend className="float-none w-auto small mb-0">SL Points</legend>
                                                    <input type="number" className="form-control" placeholder="" aria-label="Quantity" />
                                                </fieldset>
                                            </div>
                                            <div className="col-3">
                                                <fieldset className="border rounded-3 px-3">
                                                    <legend className="float-none w-auto small mb-0">SL Limit</legend>
                                                    <input type="number" className="form-control" placeholder="" aria-label="Quantity" />
                                                </fieldset>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <div>
                                            <p className='mb-0 mt-1'>Margin Available: ₹37,617.27 | Required: ₹255.23</p>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-sm btn-success fw-bold px-3"><i className="fa-solid fa-bolt"></i> Buy</button>
                                            <button type="button" className="btn btn-sm btn-danger fw-bold ms-1 px-3"><i className="fa-solid fa-bolt"></i> Sell</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className="alert alert-warning">
                    <h2 className='mb-0'>Please login first!</h2>
                </div>
            }
        </div>
    );
}

export default Home;
