import './App.css';
import { Routes, Route } from 'react-router-dom';  // Import Routes and Route

import Header from './components/Header.js';
import Home from './components/Home.js';
import NotFound from './components/NotFound.js';
import { AuthProvider } from './providers/AuthContext.js';
import Journal from './components/Journal.js';
import Profile from './components/Profile.js';
import LoginCallback from './components/LoginCallback.js';
import { FyersWebSocketProvider } from './providers/FyersWebSocketContext.js';
import LivePnL from './components/LivePnL.js';
import LivePnL2 from './components/LivePnL2.js';

function App() {
  return (
    <AuthProvider>
      <div className=''>
        <Header/>      
        <div className="container-xxl py-3">
            <FyersWebSocketProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/journal" element={<Journal />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/fyers-login-callback" element={<LoginCallback />} />
                <Route path="/live-pnl" element={<LivePnL />} />
                <Route path="/live-pnl-2" element={<LivePnL2 />} />
                
                <Route path="*" element={<NotFound />} />
              </Routes>
            </FyersWebSocketProvider>
        </div>
      </div>
    </AuthProvider>

  );
}

export default App;
