import { useEffect, useState } from "react";
import apiClient from "../utils/apiClient";

const Profile = () => {

    const [fyersId, setFyersId] = useState('');
    const [fyersAppId, setFyersAppId] = useState('');
    const [fyersSecretKey, setFyersSecretKey] = useState('');
    // const [error, setError] = useState('');

    const saveProfile = () => {
        apiClient.post('/api/auth/express-profile', {fyersAppId, fyersSecretKey, fyersId})
        .then((res)=>{
            
        })
        .catch((err)=>{

        })
    }

    const getProfile = () => {
        apiClient.get('/api/auth/express-profile')
        .then((res)=>{
            setFyersId(res.data.user.fyers_id)
            setFyersAppId(res.data.user.fyers_app_id)
            setFyersSecretKey(res.data.user.fyers_secret_key)
        })
        .catch((err)=>{

        })
    }

    useEffect(()=>{
        getProfile();
    },[])

    return (
        <div className="row">
            <div className="col-12">
                <div className="bg-light border rounded p-3">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label for="fyersId" className="form-label">Fyers Id</label>
                                <input type="text" value={fyersId} className="form-control" placeholder="Enter fyers id" id="fyersId" aria-describedby="fyersId" onChange={(e) => setFyersId(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label for="fyersAppId" className="form-label">Fyers App Id</label>
                                <input type="text" value={fyersAppId} className="form-control" placeholder="Enter fyers app id" id="fyersAppId" aria-describedby="fyersAppId" onChange={(e) => setFyersAppId(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label for="fyersSecretKey" className="form-label">Fyers Secret Key</label>
                                <input type="text" value={fyersSecretKey} className="form-control" placeholder="Enter fyers secret key" id="fyersSecretKey" aria-describedby="fyersSecretKey" onChange={(e) => setFyersSecretKey(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-2">
                            <button type="button" className="btn btn-primary w-100" onClick={()=>{saveProfile()}}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;