import React, { useContext, useEffect } from 'react';
import { useFyersWebSocketContext } from '../providers/FyersWebSocketContext';
import { AuthContext } from '../providers/AuthContext';
import LivePnL2 from './LivePnL2';
import { Link } from 'react-router-dom';
const LivePnL = () => {

    const { subscribeSymbols } = useFyersWebSocketContext();

    const { isReactLogin, fyersAccessToken } = useContext(AuthContext);

    useEffect(() => {
        // console.log('subscribe', subscribeSymbols)
        const handleMessage = (message) => {
            // Handle incoming message
            console.log('Received message comp 1:', message);
        };

        const symbols = ['NSE:MIDCPNIFTY24N0412300PE', 'NSE:TCS-EQ'];

        // Subscribe to symbols when the component mounts
        // console.log('1111')
        if(isReactLogin && fyersAccessToken){
            // console.log('2222')
            subscribeSymbols(symbols, handleMessage);
        }
        // console.log('3333')

        // Cleanup function can be added here if necessary

    }, [subscribeSymbols, isReactLogin]);

    return <div>
        <p>Comp 1</p>
        {/* <LivePnL2/> */}
        <Link to={'/live-pnl-2'}>PnL 2</Link>
    </div>;
}

export default LivePnL;