import {fyersDataSocket} from "fyers-web-sdk-v3"

var skt = null

const getFyersDataSocketInstance = (access_token=null, app_id=null) => {
  if (!skt && access_token) {
    skt = fyersDataSocket.getInstance(app_id+":"+access_token, "logs", true)
  }

  return skt;
};

export default getFyersDataSocketInstance;
