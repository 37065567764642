import React, { useState, useEffect } from 'react';

const QuoteCard = ({ quotes }) => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 10000); // 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [quotes.length]);

  const { author, quote } = quotes[currentQuoteIndex];

  return (
    <blockquote className="quote-card yellow-card rounded">
        <p>{quote}</p>
        <cite>{author}</cite>
    </blockquote>
  );
};

export default QuoteCard;
