import { fyersModel } from 'fyers-web-sdk-v3';

// Singleton instance of fyersModel
let fyers = null;

const getFyersInstance = (access_token=null, app_id=null) => {
  if (!fyers && access_token) {
    fyers = new fyersModel();
    fyers.setAppId(app_id);
    fyers.setRedirectUrl("http://139.99.89.21:3000/fyers-login-callback");
    fyers.setAccessToken(access_token);
  }

  return fyers;
};

export default getFyersInstance;
