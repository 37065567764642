// import axios from "axios";
import { Link } from "react-router-dom";
import apiClient from "../utils/apiClient";
import { useContext, useEffect, useState } from "react";
import LivePnL from "./LivePnL";
import { AuthContext } from "../providers/AuthContext";

const Header = () => {
  
  const { isExpressLogin, isFyersLogin, profile } = useContext(AuthContext);

  // const [isExpressLogin, setExpressLogin] = useState(false);
  // const [isFyersLogin, setFyersLogin] = useState(false);
  // const [profile, setProfile] = useState(null);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState('');

  // const [token, setToken] = useState(null);

  // const getProfile = () => {
  //   apiClient.get('/api/auth/express-profile')
  //   .then((res)=>{
  //       if(res.data.status===200){
  //         setExpressLogin(true)
  //       }
  //   })
  //   .catch((err)=>{
  //     console.log(err.response)
  //   })

  //   apiClient.get('/api/auth/profile')
  //   .then((res)=>{
  //       if(res.data.code===200){
  //         setFyersLogin(true)
  //         setProfile(res.data.data)
  //       }
  //   })
  //   .catch((err)=>{
  //     console.log(err.response)
  //   })
  // }

  const getLoginUrl = () => {
    apiClient.get('/api/auth/generate-login-url')
    .then((res)=>{
      // console.log('res', res.data)
        window.location.href = res.data;
    })
  }

  const logout = () => {    
    apiClient.post('/api/auth/logout')
    .then((res)=>{
      localStorage.removeItem('token');

        window.location.href = window.location.origin;
        // window.location.reload();
    })
    .catch((err)=>{
      console.log(err.response)
    })
  }

  const expressLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }

    // Perform the login API call (replace with your API endpoint)
    apiClient.post('/api/auth/express-login', {email, password})
    .then((response)=>{
      if(response){
          // setToken(response.data.token);
          localStorage.setItem('token', response.data.token);
          window.location.reload()
          console.log('Login successful:', response.data);
      } else {
          setError(response.message || 'Login failed. Please try again.');
      }
    }).catch((err) => {
      console.error('Error during login:', err);
      setError('An error occurred. Please try again.');
    });
  }
  
  // useEffect(()=>{
  //   getProfile()
  // },[])

  return (
    <>
      <header className="text-bg-dark" id="pnl-head">
        <nav className="navbar">
          <div className="container-xxl">
            <a className="navbar-brand fw-bold border border-light bg-dark px-3 rounded"><span className="text-warning">Trend</span><span className="text-info">Finder</span></a>
            <div className="d-flex11">
            
              <div className="d-inline" id="pdt-tag-span"></div>
              <div className="d-inline" id="expiry-tag-span"></div>
              <button className="btn btn-outline-dark fw-bold mx-1 bg-light" style={{width: '250px'}}>PNL: <span id="pnl-span">0</span></button>
              {/* <LivePnL/> */}
              {
                !isExpressLogin?
                  <>
                    <button type="button" className="btn btn-outline-light fw-bold mx-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Express Login
                    </button>
                  </>
                :
                <>
                  <a href="/" className="btn btn-outline-light fw-bold mx-1">Home</a>
                  <Link to="/journal" className="btn btn-outline-light fw-bold mx-1">Journal</Link>
                  <Link to="/profile" className="btn btn-outline-light fw-bold mx-1">{profile && profile.fy_id ? profile.fy_id :<>Setup Profile</>}</Link>
                  {!isFyersLogin && <button className="btn btn-outline-light fw-bold mx-1" onClick={()=>{getLoginUrl()}}>Connect Fyers</button> }
                  <button className="btn btn-outline-light fw-bold mx-1" onClick={()=>{logout()}}>Logout</button>
                </>
              }
            </div>
          </div>
        </nav>
      </header>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Login Form</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            {/* <form> */}
              <div className="mb-3">
                {/* <label htmlFor="exampleInputEmail1" className="form-label">Email address</label> */}
                <input type="email" className="form-control" placeholder="Enter email-id" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className="mb-3">
                {/* <label htmlFor="exampleInputPassword1" className="form-label">Password</label> */}
                <input type="password" className="form-control" placeholder="Enter password" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)}/>
              </div>
              <div className="mb-3">
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
              </div>
            {/* </form> */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={()=>{expressLogin()}}>Login</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;
