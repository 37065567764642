import React, { useContext, useEffect } from 'react';
import { useFyersWebSocketContext } from '../providers/FyersWebSocketContext';
import { AuthContext } from '../providers/AuthContext';
import { Link } from 'react-router-dom';

const LivePnL2 = () => {

    const { subscribeSymbols } = useFyersWebSocketContext();

    const { isReactLogin, fyersAccessToken } = useContext(AuthContext);

    useEffect(() => {
        // console.log('subscribe', subscribeSymbols)
        const handleMessage = (message) => {
            // Handle incoming message
            console.log('Received message comp 2:', message);
        };

        const symbols = ['NSE:INFY-EQ'];

        // Subscribe to symbols when the component mounts
        // console.log('1111')
        if(isReactLogin && fyersAccessToken){
            console.log('2222')
            subscribeSymbols(symbols, handleMessage);
        }
        // console.log('3333')

        // Cleanup function can be added here if necessary

    }, [subscribeSymbols, isReactLogin]);

    return <div>
        Comp 2
        <Link to={'/live-pnl'}>PnL 1</Link>
    </div>;
}

export default LivePnL2;