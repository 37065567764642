import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import apiClient from "../utils/apiClient";

const LoginCallback = () => {
    const location = useLocation();

    const getQueryParams = () => {
        return new URLSearchParams(location.search); // Create an instance of URLSearchParams
    };

    const queryParams = getQueryParams();

    useEffect(()=>{
        console.log('auth_code', queryParams.get('auth_code'))
        apiClient.get('/api/auth/fyers-login-callback?auth_code='+queryParams.get('auth_code'))
        .then((res)=>{
            window.location.href = window.location.origin;
        })
        .catch((err)=>{
            
        })
    },[])

    return (
        <div>
           
        </div>
    );
}

export default LoginCallback;