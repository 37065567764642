// // utils/formatNumber.js

// export const formatNumberIndian = (number) => {
//     if (isNaN(number)) return number; // Return the input if it's not a number

//     // Split the number into integer and decimal parts
//     const [integerPart, decimalPart] = number.toString().split('.');
    
//     // Format the integer part
//     const lastThreeDigits = integerPart.substring(integerPart.length - 3);
//     const otherDigits = integerPart.substring(0, integerPart.length - 3);
    
//     // Format the other digits with commas
//     const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    
//     // Combine the integer part
//     const formattedInteger = otherDigits ? formattedOtherDigits + ',' + lastThreeDigits : lastThreeDigits;

//     // If there is a decimal part, combine it with the formatted integer part
//     return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
// };


// utils/formatNumberIndian.js
export const formatNumberIndian = (no) => {
    if(no===null) return 0;
    let number = no;
    let neg = false;

    if(number < 0){
        neg = true;
        number = number * -1;
    }

    if (isNaN(number)) return number; // If it's not a number, return the original input

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = number.toString().split('.');

    // Format the integer part (for Indian format)
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    // Format other digits with commas (Indian format uses 2 digits after the first 3 digits)
    if (otherDigits !== '') {
        otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    }

    // Combine the formatted integer part and the decimal part (if any)
    const formattedNumber = otherDigits ? otherDigits + ',' + lastThreeDigits : lastThreeDigits;

    let res = decimalPart*1>0 ? `${formattedNumber}.${(decimalPart*1)>100?decimalPart%100 : decimalPart}` : formattedNumber;

    return neg ? '-' + res : res;
};