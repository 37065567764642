// context/FyersWebSocketContext.js

import React, { createContext, useContext, useEffect } from 'react';
import useFyersWebSocket from '../utils/fyersWebSocketManager';
import { AuthContext } from './AuthContext';

const FyersWebSocketContext = createContext();

export const FyersWebSocketProvider = ({ children }) => {
    // const { isReactLogin, fyersAccessToken } = useContext(AuthContext);
    const { connectSocket, subscribeSymbols } = useFyersWebSocket();
    const { isReactLogin, fyersAccessToken } = useContext(AuthContext);
    
    useEffect(() => {
        if (isReactLogin && fyersAccessToken) {
            // Connect the socket when logged in
            connectSocket(['NSE:SBIN-EQ']); // You can pass an initial subscription array here
        }
    }, [isReactLogin, fyersAccessToken, connectSocket]);

    return (
        <FyersWebSocketContext.Provider value={{ subscribeSymbols }}>
            {children}
        </FyersWebSocketContext.Provider>
    );
};

export const useFyersWebSocketContext = () => {
    return useContext(FyersWebSocketContext);
};
