import React, { useEffect, useRef, useState } from 'react';
import apiClient from "../utils/apiClient";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumberIndian } from '../utils/formatNumber.js';

function Journal() {
    const closeBtn = useRef();
    const currentMonth = new Date().getMonth() + 1;
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const [trades, setTrades] = useState([]);
    const [tradeSummery, setTradeSummery] = useState([])
    const [todayPnL, setTodayPnL] = useState(0)

    const [tradingDate, setTradingDate] = useState('');
    const [note, setNote] = useState('');

    const [symbol, setSymbol] = useState('BANKNIFTY');
    const [quantity, setQuantity] = useState('75');
    const [profitOrLoss, setProfitOrLoss] = useState('');
    
    const [followedRules, setFollowedRules] = useState('0');
    const [charges, setCharges] = useState(0);
    
    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ];


    useEffect(() => {
        // Set default trading date to today's date in YYYY-MM-DD format
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        setTradingDate(formattedDate);
        getTrades(formattedDate)

        getTradeSummery()
    }, []);

    useEffect(()=>{
        if(trades.length > 0){
            setTodayPnL(trades.reduce((sum, trade) => sum + trade.profit_or_loss*1, 0))
        }
    },[trades])

    const getTradeSummery = () => {
        apiClient.get('/api/trades/summery')
        .then((res)=>{
            setTradeSummery(res.data.data)
        })
        .catch((err)=>{

        })
    }

    // const handleSelectChange = (e) => {
    //     setSelectedMonth(e.target.value);
    // };

    // const handleAddDay = () => {
    //     const currentDate = new Date().toLocaleDateString();
    //     setRecords([...records, currentDate]);
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const tradeData = {
            trading_date: tradingDate,
            symbol,
            quantity: parseInt(quantity),
            profit_or_loss: parseFloat(profitOrLoss),
            note,
        };

        try {
            apiClient.post('/api/trades', tradeData)
            .then((response)=>{
                if(response.data.success){
                    // console.log('Trade created:', response.data);
                    setTrades([response.data.data, ...trades]);

                    toast.success(response.data.message);
                    
                    // Reset form fields after submission
                    setProfitOrLoss('');
                    setNote('');
                }
                else {
                    toast.error(response.data.message);
                }

            })
            .catch((err)=>{
                // toast.error(response.data.message);
                console.error('Error creating trade:', err);
            })

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit1 = async (event) => {
        event.preventDefault();

        const feedbackData = {
            trading_date: tradingDate,
            followed_rules: followedRules,
            charges,
            note,
        };

        try {
            apiClient.post('/api/feedback', feedbackData)
            .then((response)=>{
                if(response.data.success){
                    // setTrades([response.data.data, ...trades]);

                    toast.success(response.data.message);
                    
                    // Reset form fields after submission
                    setCharges(0);
                    setNote('');
                    setFollowedRules('0')

                    getTradeSummery()
                    
                    closeBtn.current.click()
                }
                else {
                    toast.error(response.data.message);
                }

            })
            .catch((err)=>{
                // toast.error(response.data.message);
                console.error('Error creating trade:', err);
            })

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getTrades = (date) => {
        apiClient.get('/api/trades?date='+date)
            .then((res)=>{
                setTrades(res.data.data)
            })
            .catch((err)=>{
                
            })
    }

    const deleteTrade = (id) => {
        apiClient.delete('/api/trades/'+id)
            .then((response)=>{
                toast.success(response.data.message);
                getTrades(tradingDate)
            })
            .catch((err)=>{
                
            })
    }

    return (
        <>
            <ToastContainer />

            {trades && trades.length>0 ?
                <div className="row mb-3">
                    <div className="col-3 text-center">
                        <div className="border rounded bg-light py-3">
                            <h4>{trades.length}</h4>
                            <h6>Total Trades</h6>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <div className="border rounded bg-light py-3">
                            <h4>Rs.{formatNumberIndian(todayPnL)}</h4>
                            <h6>Profit & Loss</h6>
                        </div>
                    </div>
                    <div className="col-6 text-center">
                        { (trades.length >= 7 || todayPnL <= -5000) &&
                            <div className="border rounded alert alert-danger mb-0 py-3">
                                <h4>Warning</h4>
                                <h6>Stop trading for the day. Control your emotion!</h6>
                            </div>
                        }
                        { trades.length < 7 && todayPnL >= -5000 &&
                            <div className="border rounded alert alert-success mb-0 py-3">
                                <h4>Good</h4>
                                <h6>Keep it up!</h6>
                            </div>
                        }
                    </div>
                </div>
                :
                <div className="row mb-3">
                    <div className="col-12 text-center alert alert-success mb-0">
                        <h2 className='mb-0'>Good Luck! Keep Calm & Trade with Patience</h2>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12">
                    <div className="card card-body bg-light mb-3">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-2">
                                    <div className="mb-3">
                                        <label htmlFor="trading_date" className="form-label">Trading Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="trading_date"
                                            value={tradingDate}
                                            onChange={(e) => {getTrades(e.target.value); setTradingDate(e.target.value)} }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="mb-3">
                                        <label htmlFor="symbol" className="form-label">Symbol</label>
                                        <select
                                            className="form-select"
                                            id="symbol"
                                            value={symbol}
                                            onChange={(e) => setSymbol(e.target.value)}
                                            required
                                        >
                                            <option value="">Select a symbol</option>
                                            <option value="BANKNIFTY">BANKNIFTY</option>
                                            <option value="NIFTY">NIFTY</option>
                                            <option value="FINNIFTY">FINNIFTY</option>
                                            <option value="MIDCAP">MIDCAP</option>
                                            <option value="SENSEX">SENSEX</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className="col-2">
                                    <div className="mb-3">
                                        <label htmlFor="quantity" className="form-label">Quantity</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="quantity"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="mb-3">
                                        <label htmlFor="profit_or_loss" className="form-label">Profit or Loss</label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            className="form-control"
                                            id="profit_or_loss"
                                            value={profitOrLoss}
                                            onChange={(e) => setProfitOrLoss(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="mb-3">
                                        <label htmlFor="note" className="form-label">Note</label>
                                        <textarea
                                            className="form-control"
                                            id="note"
                                            rows="1"
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="col-1">
                                    <label htmlFor="note" className="form-label text-light">Action</label>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-12">
                    <h5>Today Summery</h5>
                    <table className='table table-sm table-bordered'>
                        <thead className='table-dark'>
                            <tr>
                                <th width="5%">#</th>
                                <th width="10%">Date</th>
                                <th width="18%">Symbol</th>
                                <th width="7%" className='text-end'>Qty</th>
                                <th width="10%" className='text-end'>P&L(Rs.)</th>
                                <th width="40%">Note</th>
                                <th width="10%" className='text-end'>Action</th>
                                {/* <th>PT</th>
                                <th>LT</th>
                                <th>P&L</th>
                                <th>Rules</th>
                                <th>Note</th>
                                <th>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {trades.map((trade, index) => (
                                <tr key={index} className={trade.profit_or_loss>0?'table-success':'table-danger'}>
                                    <td>{index+1}</td>
                                    <td>{trade.trading_date}</td>
                                    <td>{trade.symbol}</td>
                                    <td className='text-end'>{trade.quantity}</td>
                                    <td className='text-end'>{formatNumberIndian(trade.profit_or_loss)}</td>
                                    <td>{trade.note}</td>
                                    <td className='text-end'>
                                        <span className='border rounded px-2 bg-danger text-white py-1' role='button' onClick={()=>deleteTrade(trade.id)}>Delete</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="col-12">
                    <h5>Day Wise Summery</h5>
                    <table className='table table-sm table-bordered'>
                        <thead className='table-dark'>
                            <tr>
                                <th width="5%">#</th>
                                <th width="10%">Date</th>
                                <th width="5%" className='text-end'>TT</th>
                                <th width="5%" className='text-end'>PT</th>
                                <th width="5%" className='text-end'>LT</th>
                                <th width="10%" className='text-end'>P&L</th>
                                <th width="10%" className='text-end'>Charges</th>
                                <th width="10%" className='text-end'>Net P&L</th>
                                <th width="30%">Note</th>
                                <th width="10%" className='text-end'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeSummery.map((ts, index) => (
                                <tr key={index} className={ts.total_profit_loss>0?'table-success':'table-danger'}>
                                    <td>{index+1}</td>
                                    <td>{ts.trading_date}</td>
                                    <td className='text-end'>{ts.total_trades}</td>
                                    <td className='text-end'>{ts.profitable_trades}</td>
                                    <td className='text-end'>{ts.loosing_trades}</td>
                                    <td className='text-end'>{formatNumberIndian(ts.total_profit_loss)}</td>
                                    <td className='text-end'>{formatNumberIndian(ts.charges)}</td>
                                    <td className='text-end'>{formatNumberIndian(ts.total_profit_loss*1-ts.charges*1)}</td>
                                    <td>{ts.note}</td>
                                    <td className='text-end'>
                                        <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" 
                                        onClick={()=>{
                                            setTradingDate(ts.trading_date);
                                            setNote(ts.note);
                                            setCharges(ts.charges);
                                            setFollowedRules(ts.followed_rules)
                                        }}
                                    >Edit</button>
                                    </td>
                                </tr>
                            ))}
                            <tr className='text-end table-dark fw-bold'>
                                <td colSpan={5}>Overall Trading Status</td>
                                <td>
                                    Rs. {
                                        formatNumberIndian(tradeSummery.reduce((accumulator, ts) => {
                                            return accumulator + ts.total_profit_loss*1;
                                        }, 0))
                                    }
                                </td>
                                <td>
                                    Rs. {
                                        formatNumberIndian(tradeSummery.reduce((accumulator, ts) => {
                                            return accumulator + (ts.charges*1);
                                        }, 0))
                                    }
                                </td>
                                <td>
                                    Rs. {
                                        formatNumberIndian(tradeSummery.reduce((accumulator, ts) => {
                                            return accumulator + (ts.total_profit_loss*1-ts.charges*1);
                                        }, 0))
                                    }
                                </td>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            

            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModal1Label">Feedback Update Form</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn}></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit1}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label htmlFor="trading_date1" className="form-label">Trading Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="trading_date1"
                                                value={tradingDate}
                                                onChange={(e) => setTradingDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label htmlFor="rulk" className="form-label">Followed Rules</label>
                                            <select
                                                className="form-select"
                                                id="rulk"
                                                value={followedRules}
                                                onChange={(e) => setFollowedRules(e.target.value)}
                                                required
                                            >
                                                <option value="">Select a option</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label htmlFor="charges" className="form-label">Charges</label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                className="form-control"
                                                id="charges"
                                                value={charges}
                                                onChange={(e) => setCharges(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label htmlFor="note1" className="form-label">Note</label>
                                            <textarea
                                                className="form-control"
                                                id="note1"
                                                rows="3"
                                                value={note}
                                                onChange={(e) => setNote(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-12 text-end">
                                        <button type="submit" className="btn btn-primary px-5 rounded-5 fw-bold">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Journal;
