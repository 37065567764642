import {fyersOrderSocket} from "fyers-web-sdk-v3"

var skt = null

const getFyersOrderSocketInstance = (access_token=null, app_id=null) => {
  if (!skt && access_token) {
    skt = new fyersOrderSocket(app_id+":"+access_token)
  }

  return skt;
};

export default getFyersOrderSocketInstance;
