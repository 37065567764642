import { createContext, useState, useEffect } from 'react';
// import axios from 'axios';
import apiClient from '../utils/apiClient';
import getFyersDataSocketInstance from '../utils/fyersDataSocketInstance';
import getFyersInstance from '../utils/fyersInstance';
import getFyersOrderSocketInstance from '../utils/fyersOrderSocketInstance';

// Create AuthContext
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true); // New loading state

  
  const [profile, setProfile] = useState(null);
  const [fyersAccessToken, setFyersAccessToken] = useState(null); // Add state for access_token
  const [fyersAppId, setFyersAppId] = useState(null);
  
  const [isReactLogin, setIsReactLogin] = useState(false);
  const [isFyersLogin, setIsFyersLogin] = useState(false);
  const [isExpressLogin, setExpressLogin] = useState(false);

  // Function to fetch profile and set login state
  const getProfile = async () => {
    const res = await apiClient.get('/api/auth/express-profile')
    // console.log('res', res)
    if(res.data.status===200){
      setExpressLogin(true)
    }

    apiClient.get('/api/auth/profile')
      .then((res) => {
        if (res.data.code === 200) {
          setIsFyersLogin(true)
          setProfile(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsFyersLogin(false)
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(()=>{
    if (isFyersLogin) {
      getAccessToken();
    } else {
      setIsLoading(false); // Set loading to false if not logged in
    }
  },[isFyersLogin])

  const getAccessToken = () => {
    apiClient.get('/api/auth/get-access-token')
    .then((res)=>{
        // console.log('testing', res.data)
        setFyersAccessToken(res.data.access_token);
        setFyersAppId(res.data.app_id)
        getFyersInstance(res.data.access_token, res.data.app_id)
        getFyersDataSocketInstance(res.data.access_token, res.data.app_id)
        getFyersOrderSocketInstance(res.data.access_token, res.data.app_id)
        setIsReactLogin(true)
        setIsLoading(false); // Set loading to false after completion
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false); // Ensure loading is false on error
      });
  }

  if (isLoading) {
    return <div>Loading...</div>; // Replace with a loader component if needed
  }
  
  return (
    <AuthContext.Provider value={{ isReactLogin, isFyersLogin, profile, fyersAccessToken, fyersAppId, isExpressLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
