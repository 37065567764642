// utils/fyersWebSocketManager.js

import { useEffect, useRef } from 'react';
import getFyersDataSocketInstance from './fyersDataSocketInstance';
import { useCallback } from 'react';

const useFyersWebSocket = () => {
    const socketRef = useRef(null);
    const listenersRef = useRef(new Map()); // Store listeners for each symbol

    const connectSocket = useCallback((subscriptionArray) => {
        // console.log('subscriptionArray', subscriptionArray)

        // Initialize socket only once
        if (!socketRef.current) {

            //testing start
            // const generateRandomNumber = () => Math.floor(Math.random() * (900 - 500 + 1)) + 500;
            // const intervalId = setInterval(() => {
            //     const randomNumber = generateRandomNumber();
            //     // console.log("Generated random number:", randomNumber);
            //     let res = {symbol: 'NSE:MIDCPNIFTY24N0412500CE', ltp: randomNumber, vol_traded_today: 0, last_traded_time: 1730469007, type: 'sf'}
                
            //     listenersRef.current.get('NSE:MIDCPNIFTY24N0412500CE').forEach((callback,i) => {
            //         console.log(i)
            //         callback(res)
            //     });
            // }, 1000);

            
            // return () => clearInterval(intervalId);
            //testing end

            const skt = getFyersDataSocketInstance();

            if(skt){
                skt.on('connect', () => {
                    
                    skt.subscribe(subscriptionArray);
                    skt.mode(skt.LiteMode);
                });
    
                skt.on('message', (message) => {
                    // console.log('message', message);
                    // console.log('message1', listenersRef);
                    
                    if (message.symbol && listenersRef.current.has(message.symbol)) {
                        // Notify all listeners for this symbol
                        listenersRef.current.get(message.symbol).forEach((callback) => callback(message));
                    }
                });
    
                skt.on('error', (message) => {
                    console.error('WebSocket Error:', message);
                });
    
                skt.on('close', () => {
                    console.log('Socket closed');
                });
    
                skt.connect();
                socketRef.current = skt; // Store the socket instance
            }
        } else {
            console.log('Socket already exist...')
            // If socket already exists, subscribe to new symbols
            socketRef.current.subscribe(subscriptionArray);
        }
    }, []);

    const subscribeSymbols = useCallback((symbols, callback) => {
        // console.log('symbols', symbols)
        // console.log('socketRef.current', socketRef.current)
        // console.log('listenersRef.current', listenersRef.current)

        symbols.forEach((symbol) => {
            // if (!listenersRef.current.has(symbol)) {
            //     listenersRef.current.set(symbol, []); // Initialize array for listeners
            // }
            // listenersRef.current.get(symbol).push(callback); // Add callback to the list

            if(!listenersRef.current.has(symbol)){
                listenersRef.current.set(symbol, []);
                listenersRef.current.get(symbol).push(callback);
            }
        });

        // Subscribe to the socket if it's initialized
        if (socketRef.current) {
            // console.log('1122')
            socketRef.current.subscribe(symbols);
        }
        else {
            connectSocket(symbols)
        }
    }, []);

    return { connectSocket, subscribeSymbols };
};

export default useFyersWebSocket;
